import React from 'react'
import { graphql } from 'gatsby'
import { Container,Grid,Inner} from '../components/responsive/'
import H1 from '../components/h1'
import H2 from '../components/h2'
import Content from '../components/content'
import Image from '../components/image'
import Header from '../components/header/header'
import ContainerFooter from '../components/containerFooter'
import Footer from '../components/footer/footer'
import Wrap from '../components/wrap'
import Metadata from '../components/metadata'
import './index.css'

export default ({ data }) => (
      <div>
      <Metadata title='開催場所'/>
      <Wrap>
      <Header active='venue'/>
      <Container backgroundColor='#FFF' paddingTop='1em' marginTop='4.5em'>
      <H1 fluid={data.venue.childImageSharp.fluid} info='なごのキャンパス（2019年10月28日OPEN）※旧那古野小学校'>
      開催場所
      </H1>
      <Inner marginTop='2em'>
        <Content>
        会場の「なごのキャンパス」は、旧那古野（なごの）小学校跡地の有効活用として、２０１９年１０月２８日にオープンしたばかりのコワーキングスペース・イベント会場です。<br/>
        小学校の雰囲気をそのまま活用・イノベーションし、跳び箱を再利用したテーブルや、校庭や体育館もそのまま利用することができ、コワーキングスペースとしてはたいへん珍しい場所となっています。<br/>
        <br/>
        今回は、協賛（スポンサー）いただきました日本アイ・ビー・エム株式会社様のご厚意により、オープン前から手配させていただき、ご利用させていただいております。<br/>
        </Content>

        <Grid pc='3' sp='8'>
        <Image fluid={data.venue3.childImageSharp.fluid} />
        <a href='https://nagono-campus.jp' target='_blank' rel="noopener noreferrer">https://nagono-campus.jp/</a><br/>
        </Grid>

      </Inner>
      <H2>
      アクセス
      </H2>
      <Content>
「なごのキャンパス」は名古屋駅から北東へ徒歩８分ほどの好立地にあります。
      </Content>
      <Grid pc='8' pcOffset='2'>
        <Image fluid={data.venue2.childImageSharp.fluid} />
      </Grid>
      <ContainerFooter/>
      </Container>
      <Footer/>
      </Wrap>
      </div>
)

export const query = graphql`
  query {
    venue:file(relativePath: { eq: "開催場所1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    venue2:file(relativePath: { eq: "開催場所2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    venue3:file(relativePath: { eq: "nagonocampus.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
